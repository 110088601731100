<template>
  <b-container>
    <b-row>
      <b-col class="p-3">
        <div class="title-text">Precisamos saber um pouco mais sobre a sua bike!</div>
      </b-col>
    </b-row>
    <ValidationObserver v-slot="{ invalid }" ref="observer">
      <transition name="slide">
        <form v-if="isVisible">
          <b-row class="mb-3">
            <b-col @click="openzada" md="2">
              <CustomTextInput
                :isDisabled="true"
                :placeholder="placeholder"
                type="tel"
                :isDrawer="true"
              />
              <span v-if="showErrors.brand" class="error-msg"
                >O campo Marca é obrigatório</span
              >
            </b-col>
            <b-col md="2">
              <ValidationProvider rules="required" name="Modelo" vid="model">
                <CustomTextInput
                  v-model="fields.model"
                  :model="fields.model"
                  :errors="showErrors.model"
                  @input="validateField('model')"
                  :placeholder="'Modelo'"
                  type="text"
                />
                <span v-if="showErrors.model && fields.model === ''" class="error-msg"
                  >Obrigatório</span
                >
              </ValidationProvider>
            </b-col>
            <b-row>
              <b-col class="p-3">
                <div class="title-description">
                  Informe o valor da bike (sem os centavos):
                </div>
              </b-col>
            </b-row>
            <b-col md="2">
              <ValidationProvider
                name="valor da bike"
                mode="passive"
                rules="required|valor_bike"
                v-slot="{ errors }"
              >
                <CustomTextInput
                  v-model="fields.value"
                  :model="fields.value"
                  v-mask="getCurrencyMask(fields.value)"
                  :errors="errors"
                  :placeholder="'R$'"
                  type="tel"
                />
                <span class="error-msg" v-if="showErrors.value">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-col>
          </b-row>
        </form>
      </transition>

      <button id="footerButton" class="footer-button-back" @click="previousStep">
        Voltar
      </button>
      <button
        id="footerButton"
        class="footer-button"
        @click="handleClick"
        :disabled="invalid"
      >
        Avançar
      </button>
    </ValidationObserver>

    <Drawer v-if="isVisible" drag-color="#f6f2fe" :max-height="400" ref="myBottomSheet">
      <template #header>
        <div class="text-drawer">Marcas</div>

        <div class="box-padding">
          <div class="input-box">
            <svg
              width="20"
              heigth="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15C11.381 15 12.6296 14.4415 13.5355 13.5355C14.4415 12.6296 15 11.381 15 10C15 7.23858 12.7614 5 10 5ZM3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 11.5719 16.481 13.0239 15.6063 14.1921L20.7071 19.2929C21.0976 19.6834 21.0976 20.3166 20.7071 20.7071C20.3166 21.0976 19.6834 21.0976 19.2929 20.7071L14.1921 15.6063C13.0239 16.481 11.5719 17 10 17C6.13401 17 3 13.866 3 10Z"
                  fill="#9c9b9c"
                ></path>
              </g>
            </svg>

            <input
              type="text"
              class="input-search"
              v-model="searchQuery"
              placeholder="Consultar"
              @input="handleSearch"
            />
            <svg
              @click="clearQuery"
              width="20"
              heigth="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  opacity="0.5"
                  d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                  fill="#9e9e9e"
                ></path>
                <path
                  d="M8.96967 8.96967C9.26256 8.67678 9.73744 8.67678 10.0303 8.96967L12 10.9394L13.9697 8.96969C14.2626 8.6768 14.7374 8.6768 15.0303 8.96969C15.3232 9.26258 15.3232 9.73746 15.0303 10.0304L13.0607 12L15.0303 13.9696C15.3232 14.2625 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2625 15.3232 13.9696 15.0303L12 13.0607L10.0304 15.0303C9.73746 15.3232 9.26258 15.3232 8.96969 15.0303C8.6768 14.7374 8.6768 14.2626 8.96969 13.9697L10.9394 12L8.96967 10.0303C8.67678 9.73744 8.67678 9.26256 8.96967 8.96967Z"
                  fill="#9e9e9e"
                ></path>
              </g>
            </svg>
          </div>
        </div>
      </template>
      <div class="drawer">
        <ul class="list-group">
          <li
            v-for="option in filteredOptions"
            :key="option.id"
            @click="selectBrand(option)"
            class="list-drawer"
          >
            {{ option.nome }}
          </li>
        </ul>
      </div>
    </Drawer>
  </b-container>
</template>
<script>
import CustomTextInput from "@/components/inputs/custom-text-input/CustomTextInput.vue";
import VueBottomSheetVue2 from "@webzlodimir/vue-bottom-sheet-vue2";
import * as D from "@/duck";

export default {
  props: {
    clickHandler: {
      type: Function,
      required: true,
    },
    previousStep: {
      type: Function,
      required: true,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    CustomTextInput: CustomTextInput,
    Drawer: VueBottomSheetVue2,
  },
  data: function () {
    return {
      brands: D.constants.BRANDS,
      selected: "Sim",
      isVisible: false,
      placeholder: "Marca",
      open: false,
      searchQuery: "",
      selectedUF: "",
      filteredOptions: [],
      receivedConstant: null,
      moneyOptions: {
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 0,
        masked: false,
      },
      fields: {
        brand: "",
        model: "",
        value: "",
        marca:{},
        isFormValid: false,
      },
      showErrors: {
        brand: false,
        model: false,
        value: false,
      },
    };
  },
  methods: {
    checkFormValidity() {
      this.$refs.observer.validate().then((isValid) => {
        this.fields.isFormValid = isValid;
      });
    },
    initializeFields() {
      if (this.data) {
        this.fields.brand = this.data.brand;
        this.fields.model = this.data.model;
        this.fields.value = this.data.value;
        this.fields.marca = this.data.marca;
        this.placeholder = this.data.brand || "Marca";
      }
    },
    getCurrencyMask(value) {
      if (value && value.length <= 6) {
        return "R$ ###";
      } else if (value && value.length <= 8) {
        return "R$ #.###";
      } else if (value && value.length <= 9) {
        return "R$ ##.###";
      } else if (value && value.length <= 11) {
        return "R$ #.###.###";
      } else if (value && value.length <= 12) {
        return "R$ ##.###.###";
      } else {
        return "R$ ###.###.###";
      }
    },
    validateField(field) {
      this.$refs.observer.validate(field).then(() => {
        if (field === "brand" && this.showErrors.brand === false) {
          this.showErrors[field] = false;
        } else {
          this.showErrors[field] = true;
        }
      });
    },
    handleClick() {
      this.validateField("model");
      this.validateField("value");
      this.checkFormValidity();

      if (this.fields.brand === "") {
        this.showErrors.brand = true;
      }
      if (this.fields.isFormValid === true && this.fields.brand !== "") {
        this.emitBikeData();
        this.clickHandler();
      }
    },
    clearQuery() {
      this.searchQuery = "";
      this.handleSearch();
    },
    handleSearch() {
      const query = this.searchQuery.toLowerCase();
      this.filteredOptions = this.brands.filter((option) =>
        option.nome.toLowerCase().includes(query)
      );
    },
    selectBrand(option) {
      this.showErrors.brand = false;
      this.fields.brand = option.nome;
      this.placeholder = option.nome;
      this.fields.marca = option;
      this.close();
    },
    openzada() {
      this.$refs.myBottomSheet.open();
    },
    close() {
      this.$refs.myBottomSheet.close();
    },
    handleInput(event) {
      const rawValue = event.target.value.replace(/[^0-9,]/g, "");
      const formattedValue = parseFloat(rawValue).toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      this.fields.value = formattedValue;
    },
    emitBikeData() {
      this.$emit("bikeData", this.fields);
    },

    openDrawer() {
      this.open = true; // Abrir o drawer
    },
    closeDrawer() {
      this.open = false; // Fechar o drawer
    },
  },
  watch: {
    "fields.model"() {
      this.checkFormValidity();
    },
    "fields.value"() {
      this.checkFormValidity();
    },
  },
  created() {
    this.filteredOptions = [...this.brands];
  },
  mounted() {
    this.initializeFields();
    this.isVisible = true;
    const content = this.$el.querySelector(".bottom-sheet__content");
    if (content) {
      content.style.backgroundColor = "#f6f2fe";
    }
  },
};
</script>
<style scoped>
.col,
.col-2,
.col-6 {
  padding: 0 10px;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease;
}
.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
}
.error-msg {
  font-size: 14px;
  line-height: 20px;
  color: #f70000;
}

.footer-button {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 60px;
  background-color: #4fa935;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  z-index: 9999;
}

.footer-button:disabled {
  background-color: #b1cead;
  cursor: default;
  z-index: 9999;
}

.footer-button-back {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 30%;
  height: 60px;
  z-index: 999;
  background-color: #414040;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  z-index: 9999;
}
.title-text {
  font-size: 23px;
  line-height: 24px;
  font-weight: bold;
  color: #5db247;
}

.title-link {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: #5db247;
  text-decoration: underline;
}

.input-search {
  padding: 5px;
  border: none;
  background-color: #e0e0df;
  width: 100%;
  justify-self: center;
  outline: none;
}

.continue-button {
  background-color: #5db247;
  border-radius: 25px;
  border: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  padding: 12px 30px;
  max-width: 120px;
  position: absolute;
  bottom: 1%;
  right: 2%;
}

.list-drawer {
  text-align: left;
  padding: 8px;
  margin-left: 10px;
  margin-top: 15px;
  list-style-type: none;
}

.text-drawer {
  color: #4fa935;
  padding: 8px;
  margin-left: 10px;
  font-size: 24px;
  font-weight: 700;
}

.input-box {
  width: 100%;
  background-color: #e0e0df;
  border-radius: 12px;
  display: flex;
  padding: 8px;
}
.drawer {
  margin-top: 5px;
  display: block;
  padding: 8px;
  width: 100%;
}

.box-padding {
  padding: 8px;
}

.title-description {
  margin-left: 10px;
  font-size: 16px;
  margin-top: 10px;
  line-height: 24px;
  color: #919ab0;
}
</style>
