<template>
  <div class="form-group">
    <div class="field" :class="isDrawer && 'dropdown'">
      <input class="input" :class="{
        'drawer-active': isDrawer 
        && placeholder !== 'Tipo do acessório' 
        && placeholder !== 'UF' 
        && placeholder !== 'Marca'
        && placeholder !== 'Ano'
        && placeholder !== 'Modalidade',
        '': !isDrawer,
        focused: isFocused,
        onError: errors && errors.length,
      }" @focus="isFocused = true;" @blur="isFocused = false" :readonly="isDisabled" :value="model" :type="type"
        @input="$emit('input', $event.target.value.toUpperCase())" />
      <label :class="{
        'filled-outside': isDrawer 
        && placeholder 
        !== 'Tipo do acessório' 
        && placeholder !== 'UF' 
        && placeholder !== 'Marca'
        && placeholder !== 'Ano'
        && placeholder !== 'Modalidade',
        'filled': model
      }">{{ placeholder}}</label>
      <span v-if="errors && errors.length && !isDrawer" style="font-size: 12px;"
        :style="{ color: isDisabled ? '#5db247' : 'red' }">{{ errors[0]
        }}</span>
      
    </div>
  </div>
</template>
<script>
export default {
  props: {
    model: {
      type: String,
    },
    label: {
      type: String,
    },
    action: {
      type: Function,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Placeholder",
    },
    type: {
      type: String,
      default: null,
    },
    isDrawer: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    errors: {
      errors: {
        type: Array,
        default: () => [],
      },
    },
  },
  data() {
    return {
      inputValue: "",
      isFocused: false,
      filledOutside: false,
    };
  },
  mounted() {},
  methods: {},
  watch: {}
};
</script>
<style scoped>
.dropdown {
  position: relative;
}

.dropdown::before {
  position: absolute;
  content: url('/img/arrow-down.svg');
  bottom: 20px;
  right: 20px;
}

.filled-outside {
  text-transform: uppercase;
  color: #4fa935;
}

.form-group {
  margin-top: 10px;
  margin-bottom: 0;

}

.form-group>label {
  position: absolute;
  pointer-events: none;
  top: 40px;
  overflow: hidden;
  left: 8px;
  font-size: 10px;
  padding: 0 4px;
  color: red;
  font-weight: 400;
}

.text-desc {
  color: red;
  margin-top: 4px;
}

.form-group>input {
  width: 100%;
  border: none;
  outline: none;
}

.form-group>p {
  width: 100%;
  color: red;
}

.form-group>input::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.drawer-active::placeholder {
  color: #5db247;
  font-weight: bold;
  opacity: 100% !important;
}

.drawer-inactive::placeholder {
  color: black;
  /* Cor preta para o placeholder quando isDrawer for falso */
}

.form-group>input:disabled {
  background-color: white;
  opacity: 100% !important;
  color: #9c9c9c;
}

.focused {
  border-color: #5db247;
}

.onError {
  border-color: #ff0000;
}
</style>
